import styled from 'styled-components';
import { form } from '@components/_struct/variables';

export const InputGroup = styled.div`
    display: flex;
    border: 2px solid ${form.borderDefault};
    font-size: ${form.textSizeDefault};
    background-color: #fff;
    height: 45px;
    border-radius: 6px;
    width: 100%;
    box-shadow: none;
    transition: box-shadow 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06),
        border-color 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
    .hasfocus & {
        box-shadow: 0 0 0 2px ${form.boxShadowFocus};
        border-color: ${form.borderFocus};
    }
    .haserror & {
        border-color: ${form.borderError};
    }
    .haserror.hasfocus & {
        box-shadow: 0 0 0 2px ${form.boxShadowError};
    }
    .phonefield-inline & {
        border: 0;
        border-bottom: 2px solid ${form.borderDefault};
        height: 32px;
        background-color: transparent;
        border-radius: 0;
    }
    .phonefield-inline.haserror & {
        border-color: ${form.borderError};
    }
    .phonefield-inline.hasfocus & {
        border-color: ${form.borderFocus};
        box-shadow: 0 2px 0 0 ${form.boxShadowFocus};
    }
    .phonefield-inline.hasfocus.haserror & {
        border-color: ${form.borderError};
    }
`;

export const InputText = styled.input`
    flex: 1;
    border: 0;
    background: transparent;
    padding: 6px 5px;
    border-radius: 6px;
    min-width: 0;
    outline: none;
`;

export const Select = styled.select`
    border: 0;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
`;

export const SelectCont = styled.div`
    position: relative;
    flex: 0;
`;

export const OverlaySelect = styled.div`
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${form.countryPhoneSelectorText};
    background: ${form.countryPhoneSelector};
    transition: all 0.15s ease;
    user-select: none;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 0 10px;
    ${SelectCont}:hover & {
        background: ${form.countryPhoneSelectorHover};
    }
    .phonefield-inline & {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
`;

export const CountryCallingNumber = styled.span`
    margin-left: 5px;
`;
