import React, { LabelHTMLAttributes, ReactElement } from 'react';
import { Label, LabelName, Mention } from './FormLabel.css';

interface IFormLabel extends LabelHTMLAttributes<HTMLLabelElement> {
    isRequired?: boolean;
    isOptional?: boolean;
    link?: ReactElement;
}

const FormLabel: React.FC<IFormLabel> = props => {
    const { isRequired, isOptional, htmlFor, link } = props;

    return (
        <Label htmlFor={htmlFor} {...props}>
            <LabelName>{props.children}</LabelName>
            {isRequired && <Mention>Obligatoire</Mention>}
            {isOptional && <Mention>Facultatif</Mention>}
            {link && <span>{link}</span>}
        </Label>
    );
};

export default FormLabel;
