import React from 'react';
import Footer from '../Footer';
import styled from 'styled-components';
import Scene from '@components/Event/SelectRoom/Scene';
import { CSSTransition } from 'react-transition-group';
import Logo from '@components/_common/Logo';
import DropdownLangue from '@components/_common/DropdownLangue';
import Button from '@components/_common/Button';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
import { bp } from '@components/_struct/variables';
import { useMSTranslation } from '@utils/useMSTranslation';

const HomeWrapper = styled.div`
    background: #141b50;
    z-index: 0;
    color: #fff;
    overflow: auto;
    flex-direction: column;
`;

const HomeHeader = styled.header<Pick<ISurveyLayout, 'variant'>>`
    padding: 40px;
    display: flex;
    align-items: center;
    ${p =>
        p.variant == 'singlepage' &&
        `
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
    `}
    @media (${bp.md}) {
        padding: 20px;
        margin-bottom: 30px;
        position: relative;
    }
`;

const LogoCont = styled.div<Pick<ISurveyLayout, 'variant'>>`
    max-width: 127px;
    width: 100%;
    opacity: ${p => p.variant == 'singlepage' && '0'};
    &.enter {
        opacity: 0;
        transform: translateY(30px);
    }
    &.enter-active {
        opacity: 1;
        transform: translateX(0) translateY(0);
        transition: opacity 500ms, transform 500ms;
    }
    &.enter-done {
        opacity: 1;
    }
`;

const FooterCont = styled.div`
    background: #0a0e27;
    color: #fff;
`;

interface ISurveyLayout {
    variant?: 'singlepage';
    showLogo: boolean;
}

const SurveyLayout: React.FC<ISurveyLayout> = props => {
    const { __ } = useMSTranslation(['home', 'struct']);
    const { variant, children, showLogo } = props;

    const redirectToAdmin = () => {
        window.location.href = publicRuntimeConfig.LIEN_SITE_ADMIN;
    };

    return (
        <HomeWrapper className='flex h-full relative'>
            <Scene />
            <HomeHeader variant={variant}>
                <CSSTransition in={showLogo} timeout={500}>
                    <LogoCont variant={variant}>
                        <Logo variant='white' />
                    </LogoCont>
                </CSSTransition>
                <div className='ml-auto flex'>
                    <div>
                        <DropdownLangue variant='white' />
                    </div>
                    {variant == 'singlepage' && (
                        <div className='ml-1'>
                            <Button
                                onClick={() => redirectToAdmin()}
                                variant='outline-white'
                            >
                                {__('Connexion', 'struct')}
                            </Button>
                        </div>
                    )}
                </div>
            </HomeHeader>
            {children}
            <FooterCont>
                <Footer />
            </FooterCont>
        </HomeWrapper>
    );
};

export default SurveyLayout;
