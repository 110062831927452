import styled from 'styled-components';
import { form } from '@components/_struct/variables';

export const TextAreaCont = styled.div`
    position: relative;
`;

export const TextAreaInput = styled.textarea`
    position: relative;
    border: 2px solid ${form.borderDefault};
    color: #3b3e52;
    font-size: ${form.fontSizeInput};
    padding: 6px 10px;
    background-color: #fff;
    line-height: 1.5;
    border-radius: 6px;
    resize: vertical;
    display: block;
    width: 100%;
    box-shadow: none;
    transition: box-shadow 0.15s cubic-bezier(0.27, 0.01, 0.38, 1.06),
        border-color 0.15s cubic-bezier(0.27, 0.01, 0.38, 1.06);
    &:focus {
        box-shadow: 0 0 0 2px ${form.boxShadowFocus};
        outline: 0;
        border-color: ${form.borderFocus};
    }
    ${TextAreaCont}.textarea-error & {
        border-color: ${form.borderError};
        &:focus {
            box-shadow: 0 0 0 2px ${form.boxShadowError};
        }
    }
`;
