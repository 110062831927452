import styled from 'styled-components';

export const Label = styled.label`
    display: flex;
    margin-bottom: 8px;
    font-size: 15px;
`;

export const LabelName = styled.span`
    font-weight: bold;
    flex-grow: 1;
`;

export const Mention = styled.span`
    opacity: 0.3;
`;
