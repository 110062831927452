import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    parsePhoneNumberFromString,
    CountryCallingCode,
    CountryCode,
    NationalNumber,
    E164Number,
    getCountryCallingCode
} from 'libphonenumber-js';
import { useField, useFormikContext } from 'formik';
import {
    InputGroup,
    InputText,
    Select,
    SelectCont,
    OverlaySelect,
    CountryCallingNumber
} from './PhoneField.css';
import metadata from 'libphonenumber-js/metadata.min.json';
import ReactCountryFlag from 'react-country-flag';

interface IPhoneField {
    name: string;
    id?: string;
    inline?: boolean;
    autoResize?: boolean;
}

interface IPhoneNumber {
    countryCallingCode?: CountryCallingCode;
    country?: CountryCode | string;
    nationalNumber?: NationalNumber;
    number?: E164Number;
    valid: boolean;
    possible: boolean;
    formatedIntl?: string;
}

interface ICountryItem {
    label: string;
    value: string;
}

const PhoneField = (props: IPhoneField) => {
    const [field, meta] = useField(props);
    const [codeCountry, setCodeCountry] = useState<CountryCode>('FR');
    const [inputVal, setInputVal] = useState('');
    const [phoneNumber, setPhoneNumber] = useState<IPhoneNumber>();
    const [rebuiltNumber, setRebuiltNumber] = useState('');
    const SelectCountryRef = useRef<HTMLSelectElement>(null);
    const [focus, setFocus] = useState(false);
    const { setFieldValue, setFieldTouched } = useFormikContext();

    const handleBlur = () => {
        setFieldTouched(field.name, true, false);
        setFocus(false);
    };

    const updateFormik = useCallback(
        (phoneNumber?: IPhoneNumber) => {
            if (phoneNumber) {
                setFieldValue(field.name, phoneNumber.number);
            } else {
                setFieldValue(field.name, '');
            }
        },
        [field.name, setFieldValue]
    );

    const formatInput = useCallback(
        (phoneNumber: IPhoneNumber) => {
            if (phoneNumber.formatedIntl) {
                setInputVal(
                    phoneNumber.formatedIntl
                        .replace(`+${phoneNumber.countryCallingCode}`, '')
                        .trim()
                );
            }
        },
        [setInputVal]
    );

    const selCountry = (codeCountry: string) => {
        setCodeCountry(codeCountry as CountryCode);
    };

    useEffect(() => {
        const prefix = getCountryCallingCode(codeCountry);
        setRebuiltNumber(`+${prefix}${inputVal}`);
    }, [inputVal, codeCountry]);

    useEffect(() => {
        const parsedPhoneNumber = parsePhoneNumberFromString(rebuiltNumber);
        if (parsedPhoneNumber) {
            setPhoneNumber({
                country: parsedPhoneNumber.country,
                number: parsedPhoneNumber.number,
                nationalNumber: parsedPhoneNumber.nationalNumber,
                countryCallingCode: parsedPhoneNumber.countryCallingCode,
                valid: parsedPhoneNumber.isValid(),
                possible: parsedPhoneNumber.isPossible(),
                formatedIntl: parsedPhoneNumber.formatInternational()
            });
            parsedPhoneNumber.country &&
                setCodeCountry(parsedPhoneNumber.country as CountryCode);
        } else {
            setPhoneNumber({
                valid: false,
                possible: false
            });
        }
    }, [rebuiltNumber, setPhoneNumber]);

    useEffect(() => {
        if (field.value != '') {
            if (field.value[0] != '+') {
                const prefix = getCountryCallingCode('FR');
                setRebuiltNumber('+' + prefix + field.value);
            } else setRebuiltNumber(field.value);
        }
    }, [setRebuiltNumber, field.value]);

    useEffect(() => {
        if (phoneNumber) {
            formatInput(phoneNumber);
            phoneNumber?.valid ? updateFormik(phoneNumber) : updateFormik();
        }
    }, [phoneNumber, updateFormik, formatInput]);

    const getCountriesSelectItems = useCallback(() => {
        const arr: any = [];
        for (const [key, value] of Object.entries(metadata.countries)) {
            if (Array.isArray(value)) {
                arr.push({ label: `+${value[0]}`, value: key });
            }
        }
        return arr;
    }, []);

    const countryItems: ICountryItem[] = getCountriesSelectItems();

    const countryCallinCode = getCountryCallingCode(codeCountry);

    const hasError = meta.error && meta.touched ? 'haserror' : '';
    const isFocus = focus ? 'hasfocus' : '';
    const isInlineClassName = props.inline ? 'phonefield-inline' : '';

    return (
        <div className={`${hasError} ${isFocus} ${isInlineClassName}`}>
            <InputGroup>
                <SelectCont>
                    <Select
                        name='coucou'
                        id='coucou'
                        onChange={e => selCountry(e.target.value)}
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        value={codeCountry}
                        ref={SelectCountryRef}
                    >
                        {countryItems.map((item, i) => {
                            return (
                                <option value={item.value} key={i}>
                                    {item.value} {item.label}
                                </option>
                            );
                        })}
                    </Select>
                    <OverlaySelect aria-hidden='true'>
                        <ReactCountryFlag
                            countryCode={codeCountry}
                            svg
                            style={{
                                width: '16px',
                                height: 'auto'
                            }}
                        />
                        <CountryCallingNumber>
                            +{countryCallinCode}
                        </CountryCallingNumber>
                    </OverlaySelect>
                </SelectCont>
                <InputText
                    type='text'
                    {...field}
                    {...props}
                    value={inputVal}
                    onChange={e => setInputVal(e.target.value)}
                    onFocus={() => setFocus(true)}
                    onBlur={handleBlur}
                    placeholder='Numéro de téléphone'
                    style={props.autoResize ? { width: '150px' } : {}}
                />
            </InputGroup>
        </div>
    );
};

export default PhoneField;
