import styled from 'styled-components';
import { form } from '@components/_struct/variables';

// Last update : 24/07/2020

export const FormGroupCont = styled.div`
    position: relative;
`;

export const TextFieldInput = styled.input`
    position: relative;
    border: 2px solid ${form.borderDefault};
    color: #3b3e52;
    height: 45px;
    font-size: ${form.fontSizeInput};
    padding: 6px 10px;
    background-color: #fff;
    line-height: 45px;
    border-radius: 6px;
    display: block;
    width: 100%;
    box-shadow: none;
    transition: box-shadow 0.15s cubic-bezier(0.27, 0.01, 0.38, 1.06),
        border-color 0.15s cubic-bezier(0.27, 0.01, 0.38, 1.06);
    &:focus {
        box-shadow: 0 0 0 2px ${form.boxShadowFocus};
        outline: 0;
        border-color: ${form.borderFocus};
    }
    ${FormGroupCont}.textfield-error & {
        border-color: ${form.borderError};
        &:focus {
            box-shadow: 0 0 0 2px ${form.boxShadowError};
        }
    }
    ${FormGroupCont}.textfield-inline & {
        border: 0;
        border-bottom: 2px solid ${form.borderDefault};
        color: #11182d;
        height: 32px;
        padding: 6px 5px;
        background-color: transparent;
        line-height: 31px;
        border-radius: 0;
        width: inherit;
        &:focus {
            box-shadow: 0 2px 0 0 ${form.boxShadowFocus};
            outline: 0;
            border-color: transparent transparent ${form.borderFocus}
                transparent;
        }
    }
    ${FormGroupCont}.textfield-error.textfield-inline & {
        border-color: ${form.borderError};
        &:focus {
            box-shadow: 0 2px 0 0 ${form.boxShadowError};
        }
    }
    ${FormGroupCont}.textfield-rounded & {
        border-radius: 30px;
        padding: 6px 15px;
        :not(:first-child) {
            padding-left: 40px;
        }
        :not(:last-child) {
            padding-right: 40px;
        }
    }
    ${FormGroupCont}.textfield-rounded-outline-white & {
        border-radius: 30px;
        padding: 6px 15px;
        border: 2px solid transparent;
        background-color: rgba(255, 255, 255, 0.2);
        transition: background-color 0.15s cubic-bezier(0.27, 0.01, 0.38, 1.06);
        color: #fff;
        ::placeholder {
            color: rgba(255, 255, 255, 0.5);
        }
        &:focus {
            box-shadow: 0 0 0 2px #fff;
            background-color: rgba(255, 255, 255, 0);
        }
        :not(:first-child) {
            padding-left: 40px;
        }
        :not(:last-child) {
            padding-right: 40px;
        }
    }
    &:not(:first-child) {
        padding-left: 40px;
    }
    &:not(:last-child) {
        padding-right: 40px;
    }
`;

export const TextFieldSizer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    height: 0;
    overflow: scroll;
    white-space: pre;
    font-size: ${form.fontSizeInput};
`;

export const PrefixWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 100%;
    opacity: 0.6;
    ${FormGroupCont}.textfield-rounded & {
        left: 13px;
    }
`;

export const SuffixWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 100%;
    opacity: 0.6;
    ${FormGroupCont}.textfield-rounded & {
        right: 13px;
    }
`;
