import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const Scene = (props) => {

    const { from } = props;

    let SEPARATION = 100,
        AMOUNTX = 50,
        AMOUNTY = 35;

    let camera, scene, renderer;

    let particles,
        particle,
        count = 0;

    let windowHalfX, windowHalfY;

    let mouseX = 85,
        mouseY = -342;

    const ref = useRef(null);

    let globalID;

    useEffect(() => {
        camera = new THREE.PerspectiveCamera(
            120,
            window.innerWidth / window.innerHeight,
            1,
            10000
        );
        camera.position.z = 1000;

        scene = new THREE.Scene();

        particles = new Array();

        var PI2 = Math.PI * 2;
        var material = new THREE.ParticleCanvasMaterial({
            color: 0xe1e1e1,
            program: function(context) {
                context.beginPath();
                context.arc(0, 0, 0.6, 0, PI2, true);
                context.fill();
            }
        });

        var i = 0;

        for (var ix = 0; ix < AMOUNTX; ix++) {
            for (var iy = 0; iy < AMOUNTY; iy++) {
                particle = particles[i++] = new THREE.Particle(material);
                particle.position.x =
                    ix * SEPARATION - (AMOUNTX * SEPARATION) / 2;
                particle.position.z =
                    iy * SEPARATION - (AMOUNTY * SEPARATION) / 2;
                scene.add(particle);
            }
        }

        renderer = new THREE.CanvasRenderer();
        renderer.setSize(window.innerWidth, window.innerHeight);
        ref.current.appendChild(renderer.domElement);

        // document.addEventListener('mousemove', onDocumentMouseMove, false);

        window.addEventListener('resize', onWindowResize, false);

        animate();
        // UNMOUNT
        return () => {
            cancelAnimationFrame(globalID);
            ref.current.removeChild(renderer.domElement);
        };
    }, []);

    const onWindowResize = () => {
        windowHalfX = window.innerWidth / 2;
        windowHalfY = window.innerHeight / 2;

        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(window.innerWidth, window.innerHeight);
    };

    // const onDocumentMouseMove = event => {
    //     mouseX = event.clientX - windowHalfX;
    //     mouseY = event.clientY - windowHalfY;
    // };

    const render = () => {
        camera.position.x += (mouseX - camera.position.x) * 0.05;
        camera.position.y += (-mouseY - camera.position.y) * 0.05;
        camera.lookAt(scene.position);

        var i = 0;

        for (var ix = 0; ix < AMOUNTX; ix++) {
            for (var iy = 0; iy < AMOUNTY; iy++) {
                particle = particles[i++];
                particle.position.y =
                    Math.sin((ix + count) * 0.3) * 50 +
                    Math.sin((iy + count) * 0.5) * 50;
                particle.scale.x = particle.scale.y =
                    (Math.sin((ix + count) * 0.3) + 1) * 2 +
                    (Math.sin((iy + count) * 0.5) + 1) * 2;
            }
        }

        renderer.render(scene, camera);
        count += 0.04;
    };

    const animate = () => {
        globalID = requestAnimationFrame(animate);
        render();
    };

    const styleFixed = {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        pointerEvents: 'none',
        zIndex: '-1'
    }

    const styleAbsolute = {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        pointerEvents: 'none',
        zIndex: '0'
    }

    return (
        <div
            style={from == 'home' ? styleAbsolute : styleFixed}
            ref={ref}
        />
    );
};

export default Scene;
