import React, { TextareaHTMLAttributes } from 'react';
import { useField } from 'formik';
import { TextAreaCont, TextAreaInput } from './TextArea.css';

// Last Update : 20/07/2020

interface ITextArea extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    name: string;
}

const TextArea = (props: ITextArea) => {
    const [field, meta] = useField(props);

    const hasError = meta.error && meta.touched ? 'textarea-error' : '';

    return (
        <TextAreaCont className={`${hasError}`}>
            <TextAreaInput rows={4} {...field} {...props} />
        </TextAreaCont>
    );
};

export default TextArea;
