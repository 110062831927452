import styled from 'styled-components';

export const Block = styled.div`
    border-radius: 12px;
    background: #fff;
    color: #000;
`;

export const BlockTitle = styled.h3`
    font-size: 2.3rem;
    color: #666666;
    font-weight: normal;
`;

export const BlockHead = styled.div`
    display: flex;
    margin-bottom: 40px;
`;
