import React, { useRef, useState, useEffect } from 'react';
import {
    TextFieldInput,
    FormGroupCont,
    TextFieldSizer,
    PrefixWrapper,
    SuffixWrapper
} from './TextField.css';
import { useField, FormikHandlers, useFormikContext } from 'formik';
import { Rifm } from 'rifm';

// Last update : 17/07/2020

interface TextField {
    type?: string;
    name: string;
    id?: string;
    placeholder?: string;
    autoResize?: boolean;
    onChange?: FormikHandlers['handleChange'];
    asyncValidation?: (e: any) => Promise<any>;
    asyncErrorMsg?: string;
    /** fonction de formating (voir doc de rifm) */
    format?: (string: string) => string;
    /** Variante de style */
    variant?: 'rounded' | 'inline' | 'rounded-outline-white';
    /** Contenu qui se positionne avant le champ */
    prefixElm?: JSX.Element | 'string';
    /** Contenu qui se positionne après le champ */
    suffixElm?: JSX.Element | 'string';
}

const TextField = React.memo((props: TextField) => {
    const [field, meta] = useField(props);
    const SizerRef = useRef<HTMLDivElement>(null);
    const [inputWidth, setInputWidth] = useState<number>(0);
    const {
        setFieldValue,
        setFieldTouched,
        setFieldError
    } = useFormikContext();

    const {
        type,
        autoResize,
        placeholder,
        asyncValidation,
        asyncErrorMsg,
        format,
        variant,
        prefixElm,
        suffixElm,
        onChange,
        ...rest
    } = props;

    TextField.displayName = 'TextField';

    const asType = !type ? 'text' : type;

    useEffect(() => {
        autoResize &&
            SizerRef.current &&
            setInputWidth(SizerRef.current.scrollWidth + 10);
    }, [field.value, autoResize]);

    const handleChange = (val: string) => {
        (asyncValidation &&
            !meta.error &&
            setFieldValue(field.name, val, false)) ||
            setFieldValue(field.name, val);
        onChange && onChange(val);
    };

    const handleBlur = async () => {
        if (!meta.error) {
            if (asyncValidation && asyncErrorMsg && field.value.length > 0) {
                const promise = await asyncValidation(field.value);
                if (promise == true) {
                    setFieldError(field.name, '');
                } else {
                    setFieldError(field.name, asyncErrorMsg);
                }
            }
        }
        setFieldTouched(field.name, true, false);
    };

    const variantClassName =
        variant == 'rounded'
            ? 'textfield-rounded'
            : '' || variant == 'inline'
            ? 'textfield-inline'
            : '' || variant == 'rounded-outline-white'
            ? 'textfield-rounded-outline-white'
            : '';

    const hasError = meta.error && meta.touched ? 'textfield-error' : '';

    return (
        <FormGroupCont className={`${hasError} ${variantClassName}`}>
            {prefixElm && <PrefixWrapper>{prefixElm}</PrefixWrapper>}
            {format ? (
                <Rifm
                    format={format}
                    onChange={val => handleChange(val)}
                    value={field.value}
                >
                    {({ value, onChange }) => (
                        <TextFieldInput
                            type={asType}
                            style={
                                autoResize ? { width: inputWidth + 'px' } : {}
                            }
                            {...field}
                            onChange={onChange}
                            onBlur={handleBlur}
                            value={value}
                            {...rest}
                        />
                    )}
                </Rifm>
            ) : (
                <TextFieldInput
                    type={asType}
                    style={autoResize ? { width: inputWidth + 'px' } : {}}
                    {...field}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    placeholder={placeholder}
                    {...rest}
                />
            )}
            {autoResize && (
                <TextFieldSizer ref={SizerRef} aria-hidden='true'>
                    {(field.value != '' && field.value) || placeholder}
                </TextFieldSizer>
            )}
            {suffixElm && <SuffixWrapper>{suffixElm}</SuffixWrapper>}
        </FormGroupCont>
    );
});

export default TextField;
